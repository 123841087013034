<template>
  <div id="app" class="app-page">
    <LoginDialog ref="loginDialog"></LoginDialog>
    <loading v-if="loading"></loading>
    <my-head v-if="!hiddenNav"></my-head>
    <div class="pageCenter">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view class="router-view" :class="this.$route.query.hideHeader ? 'router-view-hideHeader' : ''"
          :style="routerStyle"></router-view>
      </keep-alive>
      <router-view class="router-view" :class="this.$route.query.hideHeader ? 'router-view-hideHeader' : ''"
        :style="routerStyle" v-else></router-view>
      <my-footer v-if="!hiddenFooter"></my-footer>
    </div>
    <!--    <ActivitiesPopupManager v-if="!hiddenNav" :activities="activities"/>-->
    <ContactusDialog />
    <RigthBtn v-show="isShowRigthBtn && !hiddenNav"></RigthBtn>

    <MobileMenu v-if="!hiddenNav" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import MyHead from "./components/head";
import MyFooter from "./components/footer";

import LoginDialog from "./page/loginRegist/index.vue";
import Loading from "./components/Loading.vue";
import { getWxUser } from "@/api/LoginRegister";
import { setUserInfo, getUserInfo } from "@/static/js/userInfo";
import { pointRegister, resetChannel, setChannelFrom } from "@/plugin/webConvert";
import ActivitiesPopupManager from "@/components/ActivitiesPopupManager.vue";
import { BrowserSize } from "@/static/js/fexilb.js";
import ContactusDialog from "@/components/dialog/ContactusDialog.vue";
import RigthBtn from "./components/footer/rigthBtn.vue";
import MobileMenu from "@/page/mobileComponent/MobileMenu.vue";
var s;
export default {
  name: "App",
  components: {
    ActivitiesPopupManager,
    MyFooter,
    Loading,
    MyHead,
    LoginDialog,
    ContactusDialog,
    RigthBtn,
    MobileMenu,
  },
  provide() {
    return {
      getLoginDialog: () => {
        return this.$refs.loginDialog;
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
    routerStyle() {
      let routerStyle = {};
      if (this.$route.meta.hiddenNav) {
        routerStyle.marginTop = "0px !important";
      }
      if (this.$route.meta.hiddenFooter) {
        routerStyle.marginBottom = "0px !important";
      }
      return routerStyle;
    },
  },
  data() {
    return {
      userInfo: getUserInfo(),
      hiddenNav: true,
      hiddenFooter: true,
      activities: [
        {
          key: "炽橙杯2024",
          date: [0, Infinity],
          position: ["left", "bottom"],
          go: () => this.$router.push("/contest/hgd"),
          component: () => import("@/views/marketing/20240517/components/ActivityPopup.vue"),
        },
      ],
      isShowRigthBtn: true, // 如果是大赛页面不显示
    };
  },
  created() {
    this.$router.onReady(() => {
      const { _f, qhclickid, pmc, bd_vid } = this.$route.query;

      // 兼容百度推广的渠道参数
      let from = bd_vid ? "baidu" : _f;

      // 百度接码文档：https://dev2.baidu.com/content?sceneType=0&pageId=101211&nodeId=658&subhead=
      const registerSource = {
        360: "PLATFORM_DIANJING360",
        bing: "PLATFORM_BING",
        baidu: "PLATFORM_BAIDU",
        //与上面渠道推广互斥 并且平台推广优先级更高
        pmc: "PLATFORM_PROMOTION", //管理端配置的平台推广
      }[pmc || from];

      const extraData = {
        registerSource,
      };
      if (pmc) {
        //平台推广的额外参数
        extraData.pmc = pmc;
      } else if (qhclickid) {
        //360推广的额外参数
        extraData.qhclickid = qhclickid;
      } else if (bd_vid) {
        // 百度推广参数
        extraData.bd_vid = bd_vid;
        extraData.logidUrl = window.location.href;
      }

      pmc || from ? setChannelFrom(from, extraData) : resetChannel();

      this.hiddenFooter = this.$route.meta.hiddenFooter;
      this.hiddenNav = this.$route.meta.hiddenNav;
    });
  },
  watch: {
    $route: {
      handler(val, oldval) {
        // todo : 临时处理 不确定大赛路由一定是 '/contest/hgd'
        val.fullPath == "/contest/hgd" ? (this.isShowRigthBtn = false) : (this.isShowRigthBtn = true);
        this.hiddenFooter = this.$route.meta.hiddenFooter;
        this.hiddenNav = this.$route.meta.hiddenNav;
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    try {
      this.$refs.loginDialog.$on("successRegister", pointRegister);
      this.$once("hook:beforeDestroy", () => {
        this.$refs.loginDialog.$off("successRegister", pointRegister);
      });
    } catch (e) {
      console.error(e);
      console.error("web转化相关解析监听失败");
    }
    // 大屏适配
    BrowserSize();
    let that = this;
    window.onresize = function () {
      that.resize();
      BrowserSize();
    };
    this.resize();
    // 禁止右键
    // document.addEventListener("contextmenu", function (e) {
    //   e.preventDefault();
    // });
    // 禁止选中文字
    // document.addEventListener("selectstart", function (e) {
    //   e.preventDefault();
    // });

    if (window.location.href.indexOf("?isLogin=7") != -1 && !this.userInfo) {
      that.$store.commit("changeLoginDialogVisible", 7);
    }
    this.getIsAuth();
  },
  methods: {
    ...mapMutations(["setIsMobile"]),
    getIsAuth() {
      let u = location.href; //拿取当前url
      let href = u.split("?"); //将url截开

      if (!this.userInfo && href.length >= 2) {
        //根据url后面是不是有参数来判断是否有获得微信code值
        let code = u.split("code=")[1]?.split("&")[0];
        if (code) {
          getWxUser({ code: code }).then((res) => {
            if (res.code == 200) {
              var userInfo = res.userInfo;
              userInfo.token = res.data;
              console.log(userInfo.token);
              setUserInfo(userInfo);
              this.$showMessage("s", "登录成功");
              this.$router.push("/");
            }
          });
        }
      }
    },
    resize() {
      // 判断当时是否是iPod
      if (navigator.userAgent.match(/(pad|iPod|iPad)/i) && window.screen.width > 750) {
        s = window.screen.width / 1084;
        document.body.style.zoom = s;
        document.body.style.cssText += "; -moz-transform: scale(" + s + ");-moz-transform-origin: 0 0; ";
        document.body.style.overflowX = " hidden !important";
        // 判断是不是pc访问
      } else if (navigator.userAgent.match(/(wOSBrowser|BrowserNG|WebOS)/i) && window.screen.width > 750) {
        s = window.screen.width / 1920;
        document.body.style.zoom = s;
        document.body.style.cssText += "; -moz-transform: scale(" + s + ");-moz-transform-origin: 0 0; ";
      }

      if (window.screen.width < 768) {
        this.setIsMobile(true);
      } else {
        this.setIsMobile(false);
      }
    },
  },
};
</script>

<style src="./static/css/app.scss" lang="scss">
// 隐藏video 音量按钮
video::-webkit-media-controls-mute-button {
  display: none !important;
}

// 隐藏video 当前按钮
video::-webkit-media-controls-current-time-display {
  display: none !important;
}

// 隐藏video 总时间
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

// 隐藏video 全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
}
</style>
<style lang="scss">
#app,
body,
html,
* {
  font-family: PingFangSC-Medium, PingFang SC, Microsoft YaHei, HanHei SC, Helvetica Neue, Open Sans, Arial, Hiragino Sans GB;
  font-weight: 500;
}

body {
  // min-width: 1200px;
  overflow-x: auto;
}

@font-face {
  font-family: "Nohemi";
  src: url(./font/Nohemi-Regular.woff2) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.router-view-hideHeader {
  padding-top: 0 !important;
}

// @font-face {
//   font-family: 'YouSheBiaoTiHei';
//   src: url(./font/YouSheBiaoTiHei-2.ttf) format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Source Han Sans";
//   /* Project id 3593052 */
//   src: url('./font/SourceHanSansSC-Normal-2.otf') format('truetype');
// }

// @font-face {
//   font-family: 'PingFang';
//   src: url(./font/PingFang.ttc) format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'YouSheBiaoTiHei';
//   src: url(./font/YouSheBiaoTiHei-2.ttf) format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'PingFang';
//   src: url(./font/PingFang.ttc) format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }
.app-page {
  position: relative;
}

.el-dialog {
  border-radius: 32px !important;
  background-color: rgba(128, 128, 128, 0.75) !important;
  backdrop-filter: blur(15px) saturate(100%) !important;
  position: relative !important;
  box-shadow: 0px 12px 26px 0px rgba(0, 0, 0, 0.1) !important;
}


.el-dialog::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 32px;
  padding: 2px;
  pointer-events: none;
  background: linear-gradient(156.52deg, rgba(255, 255, 255, 0.4) 2.12%,
      rgba(255, 255, 255, 0.0001) 39%, rgba(255, 255, 255, 0.0001) 54.33%,
      rgba(255, 255, 255, 0.19) 93.02%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}


//弹窗样式
.card-dialog {
  border-radius: 32px;
  background-color: rgba(128, 128, 128, 0.5) !important;
  backdrop-filter: blur(15px) saturate(100%);
  box-shadow: 0px 12px 26px 0px rgba(0, 0, 0, 0.1);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 32px;
    padding: 2px;
    pointer-events: none;
    background: linear-gradient(156.52deg, rgba(255, 255, 255, 0.4) 2.12%,
        rgba(255, 255, 255, 0.0001) 39%, rgba(255, 255, 255, 0.0001) 54.33%,
        rgba(255, 255, 255, 0.19) 93.02%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.el-dialog__headerbtn .el-dialog__close {
  color: #ffffff !important;
}

/**文本框 */
.el-input {
  .el-input__inner {
    background-color: #c2c2c2;
    border: 0px solid #dcdfe6;
    border-radius: 10px;
    color: #555 !important;

    &::placeholder {
      color: #888 !important;
    }
  }

  .el-input__clear {
    color: #fff !important;
  }
}

/**下拉框 */
.el-select-dropdown {
  background-color: rgba(85, 85, 85, 1) !important;
  border: 0px solid #dcdfe6 !important;
}

.el-select-dropdown__item {
  color: rgba(194, 194, 194, 1) !important;
}

// .el-popper[x-placement^="bottom"] .popper__arrow::after {
//   border-bottom-color: #fff !important;
//   top: 0 !important;
// }

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: rgba(82, 82, 82, 1) !important;
}

html,
body,
#app {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100%;
  text-align: center;
  font-size: 14px;
  color: #333333;
  position: relative;
  background: rgba(247, 248, 250, 1);
  font-family: "Microsoft YaHei" !important;
}

.pageCenter {
  width: 100%;
  min-height: calc(100vh - 365px);
  min-width: $mainCenter;
}

.myPopover {
  background: #ffffff;
  border-radius: 20px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {

  /* //滚动条样式 */
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 0px;
    background-color: #95999c;
    -webkit-border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 0px;
    background-color: #95999c;
    -webkit-border-radius: 0px;
  }

  /* //滚动条鼠标移上去的样式 */
  ::-webkit-scrollbar-thumb:vertical:hover,
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #808080;
  }
}

* {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

.loginPop {
  text-align: left !important;
  padding: 0 !important;

  .popper__arrow {
    display: none !important;
  }
}

/* 添加黑色主题样式 */
.el-cascader-panel {
  background-color: #48484a !important;
  color: #fff;
  border: 1px solid #48484a;
}

.el-cascader-panel .el-scrollbar__view {
  color: #fff;
}

.el-cascader-node {
  flex-wrap: nowrap;
}

.el-cascader-node:hover {
  background-color: #48484a;
}

.el-cascader-node .is-active {
  color: #f57f22;
  background-color: #48484a;
}

.el-cascader-node .el-checkbox__input {
  border-color: #48484a;
}

.el-cascader-node .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #fff;
  border-color: #48484a;
}

.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
  color: #f57f22;
  background: transparent !important;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #f57f22 !important;
}

.el-select .el-input__inner {
  background-color: #fff !important;
  border: 1px solid #d1d1d6 !important;
}
</style>
